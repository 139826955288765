*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --maxPageWidth: 75rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

svg,
img {
  display: block;
}
